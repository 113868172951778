import { Col, Container, Row } from "react-bootstrap";
import TopNavbar from "./TopNavbar";
import SelectedVideoProvider from "../context/MediaContext";
import { ReactNode } from "react";
import SelectedLocationProvider from "../context/RegionContext";
import SelectedIdentifiedObjectProvider from "../context/IdentifiedObjectContext";
import { UserLocationProvider } from "../context/UserLocationContext";

interface PageLayoutProps {
  children: ReactNode;
  title?: string;
}

const PageLayout = ({ children, title }: PageLayoutProps) => {
  return (
    <div>
      <TopNavbar />

      <Container fluid>
        <UserLocationProvider>
          <SelectedLocationProvider>
            <SelectedVideoProvider>
              <SelectedIdentifiedObjectProvider>
                <header className="pt-4">
                  <Row className="justify-content-center">
                    <Col lg={11} className="text-start">
                      {title && <h5 className="font-bold">{title}</h5>}
                    </Col>
                  </Row>
                </header>
                <main>
                  <Row>
                    <Col lg={11} className="mx-auto pb-0">
                      {children}
                    </Col>
                  </Row>
                </main>
              </SelectedIdentifiedObjectProvider>
            </SelectedVideoProvider>
          </SelectedLocationProvider>
        </UserLocationProvider>
      </Container>
    </div>
  );
};

export default PageLayout;
