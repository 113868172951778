import { useEffect, useState } from "react";
import BarChart from "./BarChart";
import { CHART_BG_COLORS, CHART_BORDER_COLORS } from "../../utils/reports";
import {
  IdentifiedObjectItem,
  IdentifiedSpeciesCount,
} from "../../models/Media";
import { CameraItem } from "../../models/Camera";
interface SpeciesByYearOverMontProps {
  cameras: CameraItem[];
  selectedCameras: CameraItem[];
  allIdentifiedObjects: IdentifiedObjectItem[];
  selectedIdentifiedObjects: IdentifiedObjectItem[];
  reportData: IdentifiedSpeciesCount[];
  yearNames: string[];
}

export const getTotalCount = (
  object_name: string,
  monthIndex: number,
  yearName: string,
  reportData: any[],
  cameras: CameraItem[]
): number | null => {
  let total_count = 0;
  if (reportData) {
    let filteredReportData = reportData?.filter(
      (data: any) => object_name === data.identified_object_name
    );

    for (const data of filteredReportData) {
      for (const camera of cameras) {
        // Convert the date from the data to a JavaScript Date object
        const dataDate = new Date(data.date);
        // Check if the date is within the selected range, if provided
        const isWithinMonth = dataDate.getMonth() === monthIndex;

        const isWithinYear = dataDate.getFullYear().toString() === yearName;

        if (isWithinMonth && isWithinYear && camera.camera_id === data.camera)
          total_count += data.count;
      }
    }

    return total_count > 0 ? total_count : null;
  }

  return null;
};
export const getCount = (
  object_name: string,
  monthIndex: number,
  yearName: string,
  camera: string,
  reportData: IdentifiedSpeciesCount[]
): number => {
  let total_count = 0;
  if (reportData) {
    let filteredReportData = reportData?.filter(
      (data: any) => object_name === data.identified_object_name
    );

    for (const data of filteredReportData) {
      // Convert the date from the data to a JavaScript Date object
      const dataDate = new Date(data.date);
      // Check if the date is within the selected range, if provided
      const isWithinMonth = dataDate.getMonth() === monthIndex;

      const isWithinYear = dataDate.getFullYear().toString() === yearName;

      if (isWithinMonth && isWithinYear && camera === data.camera)
        total_count += data.count;
    }
    return total_count;
  }

  return 0;
};
const SpeciesOverMonthsByYear = ({
  cameras,
  selectedCameras,
  allIdentifiedObjects,
  selectedIdentifiedObjects,
  reportData,
  yearNames,
}: SpeciesByYearOverMontProps) => {
  const [mapData, setMapData] = useState<any>(null);
  const [csvData, setCsvData] = useState<any>();
  const ChartName = "Species Over Months by Year";

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const resultArrays: any = (objectName: string, yearName: string) =>
    monthNames?.map((monthName, monthIndex) => {
      const count = getTotalCount(
        objectName,
        monthIndex,
        yearName,
        reportData,
        cameras
      );
      return {
        month: monthName,
        object: {
          name: objectName,
          count: typeof count === "number" ? count : 0, // Fallback to 0 if not a number
        },
      };
    });
  // Create the customized formatted data for exporting CSV file
  const csvArrays: any = (objectName: string, yearName: string) =>
    selectedCameras?.flatMap((camera) => {
      return monthNames?.map((monthName, monthIndex) => {
        let regionObject: any = {
          camera: camera.camera_id,
          Object: objectName,
          Year: yearName,
          Month: monthName,
          Count: getCount(
            objectName,
            monthIndex,
            yearName,
            camera.camera_id,
            reportData
          ),
        };
        return regionObject;
      });
    });

  // Use useEffect to update mapData when regions or selectedSpecies change
  useEffect(() => {
    // Load all csv related data
    const csv = selectedIdentifiedObjects?.flatMap((objectName) =>
      yearNames?.flatMap((yearname, yearIndex) =>
        csvArrays(objectName.name, yearname.toString())
      )
    );

    setCsvData(csv);
  }, [selectedCameras, selectedIdentifiedObjects, yearNames]);

  // Use useEffect to update mapData when regions or selectedSpecies change
  useEffect(() => {
    if (selectedCameras && selectedIdentifiedObjects) {
      const data = {
        datasets: selectedIdentifiedObjects?.flatMap((objectName) =>
          yearNames?.map((yearname, yearIndex) => ({
            data: resultArrays(objectName.name, yearname.toString()),
            backgroundColor:
              CHART_BG_COLORS[Object.keys(CHART_BG_COLORS)[yearIndex]],
            borderColor:
              CHART_BORDER_COLORS[Object.keys(CHART_BORDER_COLORS)[yearIndex]],
            borderWidth: 1,
            parsing: {
              xAxisKey: "month",
              yAxisKey: "object.count",
            },
          }))
        ),
      };
      setMapData(data);
    }
  }, [selectedCameras, selectedIdentifiedObjects, reportData, yearNames]);

  const calculateDynamicScale = (data: any) => {
    if (!data || !data.datasets) return { suggestedMax: 100, stepSize: 50 }; // Default values if data is null

    const allValues = data.datasets.flatMap((dataset: any) =>
      dataset.data.map((entry: any) => entry.object.count)
    );
    const maxValue = Math.max(...allValues);

    // Set the suggestedMax to be 50 ticks more than the highest value
    const suggestedMax = Math.ceil((maxValue + 50) / 50) * 50;

    return { suggestedMax, stepSize: 50 };
  };

  // Using mapData conditionally to avoid error
  const { suggestedMax, stepSize } = mapData
    ? calculateDynamicScale(mapData)
    : { suggestedMax: 100, stepSize: 50 };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          generateLabels: (chart: any) => {
            return yearNames.map((yearName, index) => {
              const dataset = chart.data.datasets[index];
              return {
                text: `${yearName}`,
                fillStyle: dataset?.backgroundColor || "grey",
                hidden: !chart.isDatasetVisible(index),
                lineCap: dataset?.borderCapStyle || "butt",
                lineDash: dataset?.borderDash || [],
                lineDashOffset: dataset?.borderDashOffset || 0,
                lineJoin: dataset?.borderJoinStyle || "miter",
                lineWidth: dataset?.borderWidth || 1,
                strokeStyle: dataset?.borderColor || "black",
                pointStyle: dataset?.pointStyle || "rect",
                datasetIndex: index,
              };
            });
          },
        },
      },
      title: {
        display: true,
        text: "Animals by Year Over Month",
        position: "right",
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      y: {
        min: 0,
        suggestedMax: suggestedMax, // Dynamically set suggestedMax
        ticks: {
          stepSize: stepSize, // Dynamically set stepSize
        },
      },
    },
  };

  return (
    <BarChart
      mapData={mapData}
      options={options}
      csvData={csvData}
      name={ChartName}
    />
  );
};

export default SpeciesOverMonthsByYear;
