import {
  Button,
  Col,
  FormControl,
  InputGroup,
  ListGroup,
  Row,
} from "react-bootstrap";
import { User } from "../models/User";
import { useEffect, useState } from "react";
import AddUser from "./AddUser";
import CustomModal from "./CustomModal";
import { deleteUser, getUsers } from "../services/Users";
import { toast } from "react-toastify";
import Spinner from "./Spinner";
import { FaSearch, FaTimes } from "react-icons/fa";
import { NotificationPaginator } from "./NotificationPaginator";

const UserEntries = () => {
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [formData, setFormData] = useState<any>(null);
  const [pageData, setPageData] = useState<any>(null); // For pagination data
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); // Search input state
  const [userFilter, setUserFilter] = useState(""); // Search filter state
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false); // Only for fetching new data
  const itemsPerPage = 9; // Number of items per page

  const loadUsers = () => {
    setLoading(true);
    const getUsers$ = getUsers(pageNumber, userFilter, itemsPerPage);
    const sub = getUsers$.subscribe({
      next: (res: any) => {
        setPageData(res); // Pagination data
        setUsers(res.results);
        setLoading(false);
      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, userFilter]);

  // Handle page change for pagination
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };

  // Handle search query when the user presses "Enter"
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setUserFilter(searchQuery); // Update the filter state
      setPageNumber(1); // Reset to the first page
    }
  };

  // Handle manual search via the search button
  const handleSearch = () => {
    setUserFilter(searchQuery);
    setPageNumber(1); // Reset to the first page
  };

  const onUserDelete = () => {
    const toastId = toast.loading("Please wait...");
    const deleteUser$ = deleteUser(formData.id);
    const sub = deleteUser$.subscribe({
      next: (res: any) => {
        loadUsers();
        setShowDeleteModal(false);
        toast.update(toastId, {
          render: "User has been deleted successfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: false,
          theme: "colored",
        });
      },
      error: (e) => {
        setShowDeleteModal(false);
        toast.update(toastId, {
          render: "Error! Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: false,
        });
        console.error("Error", e);
      },
    });
    return () => sub.unsubscribe();
  };

  return (
    <>
      <CustomModal
        show={showAddUserModal}
        scrollable={false}
        modalBody={
          <AddUser
            onClose={() => {
              setShowAddUserModal(false);
              loadUsers();
            }}
          />
        }
      />
      <CustomModal
        cancelButtonText={"Cancel"}
        confirmButtonText={"Delete"}
        modalTitle={"Delete user"}
        show={showDeleteModal}
        scrollable={true}
        modalBody={
          <div>
            Are you sure you want to delete{" "}
            <b>
              {formData?.name}({formData?.email})
            </b>{" "}
            user account?
          </div>
        }
        onModalClose={() => {
          setFormData(null);
          setShowDeleteModal(false);
        }}
        onConfirmed={() => onUserDelete()}
      />
      <Row className="mb-2 justify-content-md-end g-0">
        {/* Search Input - Expands to Full Width on Small Screens */}
        <Col xs={12} md="auto" className="mb-2 mb-md-0 me-md-2">
          <InputGroup>
            <FormControl
              placeholder="Search by Name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyPress} // Trigger search on Enter
              style={{
                paddingRight: "30px", // Add padding to make room for the icon
              }}
            />
            {searchQuery && (
              <span
                onClick={() => {
                  setSearchQuery(""); // Clear the search query
                  setUserFilter("");
                  setPageNumber(1); // Reset pagination
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    setSearchQuery("");
                    setUserFilter("");
                    setPageNumber(1);
                  }
                }}
                tabIndex={0} // Makes the element focusable
                role="button" // Indicates it's a clickable element
                aria-label="Clear search" // Adds an accessible label for screen readers
                style={{
                  position: "absolute",
                  right: "55px",
                  top: "50%",
                  transform: "translateY(-50%)", // Center vertically
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  border: "none",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaTimes /> {/* Clear icon */}
              </span>
            )}
            <Button variant="secondary" onClick={handleSearch}>
              <FaSearch />
            </Button>
          </InputGroup>
        </Col>

        {/* Add User Button - Expands to Full Width on Small Screens */}
        <Col xs={12} md="auto">
          <Button
            variant="primary"
            onClick={() => setShowAddUserModal(true)}
            className="w-100 w-md-auto"
          >
            + Add User
          </Button>
        </Col>
      </Row>
      {/* No results found */}
      {users.length === 0 && (
        <div className="mt-3 d-flex justify-content-center bg-white">
          <div className="no-results-found p-4 text-center">
            <h5>No Users Found</h5>
            <p>Try adjusting your search or adding new users.</p>
          </div>
        </div>
      )}
      {/* User List */}
      <Row className="position-relative g-2">
        <ListGroup>
          {users.map((user) => (
            <ListGroup.Item
              className="d-flex justify-content-between align-items-start mb-1 px-3"
              key={user.id}
            >
              <div
                className="ms-2 me-auto p-0 flex-grow-1"
                style={{ minWidth: 0 }}
              >
                <div>
                  <span className="fw-bold small-device-text">{`${user.first_name} ${user.last_name} (${user.username})`}</span>
                </div>
                <div style={{ maxWidth: "100%" }}>
                  {user.groups && user.groups.length > 0 ? (
                    <small>Member of {user.groups.join(", ")} group(s)</small>
                  ) : (
                    <small>No group assigned yet</small>
                  )}
                </div>
              </div>
              <div className="list-item-wrapper d-flex align-items-center ms-2">
                <button
                  type="button"
                  className="btn list-action-button btn-light"
                  onClick={() => {
                    setFormData(user);
                    setShowDeleteModal(true);
                  }}
                >
                  <i
                    className="bi bi-trash3"
                    id={`delete-user-entry-${user.id}`}
                  ></i>
                </button>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
        {loading && (
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.7)", zIndex: 1 }}
          >
            <Spinner />
          </div>
        )}
      </Row>

      {/* Paginator */}
      {pageData && (
        <NotificationPaginator
          currentPage={pageNumber}
          totalItems={pageData.count}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default UserEntries;
