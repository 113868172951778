import React from "react";
import { Button } from "react-bootstrap";

interface PaginatorProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
}

export const NotificationPaginator: React.FC<PaginatorProps> = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}) => {
  // Calculate total pages
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // If there are no items or pages to paginate, return null
  if (totalItems === 0 || totalPages === 0) {
    return null;
  }

  // Function to render page options for the dropdown
  const renderPageOptions = () => {
    const pageOptions = [];
    for (let i = 1; i <= totalPages; i++) {
      pageOptions.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return pageOptions;
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between", // Align elements on opposite sides
        padding: "10px",
        width: "100%",
      }}
    >
      {/* Left side: Page label, dropdown, and total pages text */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "10px" }}>Page</span>
        <select
          value={currentPage}
          onChange={(e) => onPageChange(Number(e.target.value))}
          style={{ marginRight: "10px" }}
        >
          {renderPageOptions()}
        </select>
        <span>of {totalPages}</span>
      </div>

      {/* Right side: Previous and Next buttons */}
      <div>
        <Button
          size="sm"
          variant="secondary"
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
          style={{ marginRight: "10px" }}
        >
          Previous
        </Button>
        <Button
          size="sm"
          variant="primary"
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
