import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import InputIcon from "../utils/input_icon";

interface DateYearPickerProps {
  yearNames: any; // Type could be either Value from "react-multi-date-picker" or string[]
  setYearNames: React.Dispatch<React.SetStateAction<any>>;
}

const DateYearPicker: React.FC<DateYearPickerProps> = ({
  yearNames,
  setYearNames,
}) => {
  useEffect(() => {
    // Set initial value of year input box
    setYearNames([new Date().getFullYear()]);
  }, []);

  const formattedYearNames = Array.isArray(yearNames)
    ? yearNames
        .map((date) =>
          typeof date === "object" && date instanceof Date
            ? date.getFullYear()
            : new Date(date).getFullYear()
        )
        .join(", ")
    : "";

  return (
    // <div>
    <>
      <span className="d-block fw-bold mb-1">
        <small>Year Range:</small>
      </span>
      <Form className="w-100">
        <Form.Group
          className="mb-3 w-100"
          controlId="exampleForm.ControlInput1"
        >
          <DatePicker
            value={yearNames}
            onChange={setYearNames}
            onlyYearPicker
            multiple
            // sort
            plugins={[<DatePanel />]}
            style={{
              width: "60%",
              height: "26px",
            }}
            containerStyle={{
              width: "100%",
            }}
            render={
              <InputIcon
                className="form-control mb-3 bg-white w-90"
                value={formattedYearNames}
                openCalendar={() => {}}
                separator=", "
                locale="en" // Adjust locale as needed
                iconStyle={{ cursor: "pointer" }}
              />
            } // render={
            //   <div style={{ position: "relative", width: "100%" }}>
            //     <Icon
            //       style={{
            //         position: "absolute",
            //         right: "15px",
            //         top: "50%",
            //         transform: "translateY(-50%)",
            //         cursor: "pointer",
            //         pointerEvents: "none",
            //         color: "grey",
            //         width: "25px",
            //         height: "25px",
            //       }}
            //     />
            //     <input
            //       type="text"
            //       // value={yearNames?.join(", ") || ""}
            //       value={formattedYearNames}
            //       className="form-control mb-3 bg-white w-90"
            //       style={{ height: "40px" }}
            //     />
            //   </div>
            // }
          />
        </Form.Group>
      </Form>
    </>
  );
};

export default DateYearPicker;
