import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import griffithLogo from "../assets/images/GRIFF1_STD_RGB_reverse.png";
import "../scss/dashboard/components/_footer.scss";

const Footer = () => (
  <footer id="Footer" className="text-light py-2 m-0 footer">
    <Container fluid className="px-5">
      {/* <div className="footer-container"> */}
      <Row className="justify-content-between">
        <Col xs={6} className="d-flex align-items-center">
          <a
            href="https://www.griffith.edu.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Griffith Logo" src={griffithLogo} height={38} />
          </a>
        </Col>
        <Col
          xs={6}
          className="d-flex justify-content-end align-items-center footer-text"
        >
          CRICOS Provider - 00233E | TEQSA - PRV12076
        </Col>
      </Row>
      {/* </div> */}
    </Container>
  </footer>

  // <footer className="bg-dark w-100 py-2">
  //   <div className="container d-flex flex-column flex-sm-row align-items-center justify-content-between">
  //     {/* Logo */}
  //     <div className="d-flex justify-content-center mb-3 mb-md-0">
  //       <a href="/" className="d-flex align-items-center">
  //         {/* Responsive image for small and large screens */}
  //         <a
  //           href="https://www.griffith.edu.au"
  //           target="_blank"
  //           rel="noopener noreferrer"
  //         >
  //           <img alt="Griffith Logo" src={griffithLogo} height={38} />
  //         </a>
  //       </a>
  //     </div>

  //     {/* CRICOS Information */}
  //     <div className="text-center text-white">
  //       <p className="mb-0 small">
  //         CRICOS Provider - 00233E | TEQSA - PRV12076
  //       </p>
  //     </div>
  //   </div>
  // </footer>
);

export default Footer;
