import React from 'react';
import Select, { StylesConfig } from 'react-select';
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { Form } from 'react-bootstrap';

interface CustomStylesConfig extends StylesConfig {
    zIndex?: number;
}

interface SelectInputProps {
    name: string;
    id: string;
    control: Control<any>;
    options: any[];
    placeholder: string;
    label: string;
    styles?: CustomStylesConfig;
    isMulti: boolean;
    fieldError?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
    handleFocus?: () => void
}

const SelectInput: React.FC<SelectInputProps> = ({ name, control, options, placeholder, label, styles, isMulti, handleFocus, fieldError, id }) => {
    return (
        <div className='mt-3'>
            <Form.Label>{label}</Form.Label>
            <Controller
                name={name}
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                    <Select
                        {...field}
                        isMulti={isMulti}
                        id={id}
                        onFocus={handleFocus}
                        options={options}
                        placeholder={placeholder}
                        classNamePrefix="select"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: '#F5F8FB',
                                borderColor: '#d1d7e0',
                                ...styles?.control,
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                color: '#506690',
                                ...styles?.placeholder,
                            }),
                            ...styles,
                        }}
                    />
                )}
            />
            {fieldError &&
                <span className="text-danger" data-testid="error-message">{fieldError as string}</span>
            }
        </div>
    );
};

export default SelectInput;
