import React, { FC } from "react";
import { Modal } from "react-bootstrap";

interface CustomModalProps {
  show: boolean;
  onModalClose?: (() => void) | undefined;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onConfirmed?: (() => void) | undefined;
  modalTitle?: string;
  modalBody: React.ReactNode;
  scrollable?: boolean;
  modalClassName?: string;
}

const CustomModal: FC<CustomModalProps> = ({
  show,
  onModalClose,
  cancelButtonText,
  confirmButtonText,
  onConfirmed,
  modalTitle,
  modalBody,
  scrollable,
  modalClassName,
}) => {
  return (
    <>
      <Modal
        show={show}
        className={modalClassName}
        scrollable={scrollable}
        backdrop="static"
        onHide={onModalClose}
      >
        {modalTitle && (
          <Modal.Header>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>{modalBody}</Modal.Body>
        {(cancelButtonText || confirmButtonText) && (
          <Modal.Footer>
            {cancelButtonText && (
              <button
                className="btn btn-secondary form-button"
                data-testid="close-button"
                onClick={onModalClose}
              >
                {cancelButtonText}
              </button>
            )}
            {confirmButtonText && (
              <button
                className="btn btn-primary form-button"
                data-testid="confirm-button"
                onClick={onConfirmed}
              >
                {confirmButtonText}
              </button>
            )}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default CustomModal;
