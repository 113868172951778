export const CHART_BORDER_COLORS: { [key: string]: string } = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
  secondOrange: "rgb(196, 76, 12)",
  secondYellow: "rgb(196, 189, 13)",
  secondPink: "rgb(171, 13, 82)",
  secondBlue: "rgb(39, 63, 224)",
  secondred: "rgb(247, 4, 4)",
  thirdBlue: "rgb(8, 161, 191)",
  thirdYellow: "rgb(230, 230, 0)",
  thirdGreen: "rgb(0, 102, 0)",
  thirdPink: "rgb(255, 204, 229)",
  thirdBrown: "rgb(51, 26, 0)",
  thirdLime: "rgb(0, 255, 0)",
};

export const CHART_BG_COLORS: { [key: string]: string } = {
  red: "rgb(255, 99, 132, 0.5)",
  orange: "rgb(255, 159, 64, 0.5)",
  yellow: "rgb(255, 205, 86, 0.5)",
  green: "rgb(75, 192, 192, 0.5)",
  blue: "rgb(54, 162, 235, 0.5)",
  purple: "rgb(153, 102, 255, 0.5)",
  grey: "rgb(201, 203, 207, 0.5)",
  secondOrange: "rgb(196, 76, 12, 0.5)",
  secondYellow: "rgb(196, 189, 13,0.5)",
  secondPink: "rgb(171, 13, 82, 0.5)",
  secondBlue: "rgb(39, 63, 224, 0.5)",
  secondred: "rgb(247, 4, 4,0.5)",
  thirdBlue: "rgb(8, 161, 191,0.5)",
  thirdYellow: "rgb(230, 230, 0,0.5)",
  thirdGreen: "rgb(0, 102, 0,0.5)",
  thirdPink: "rgb(255, 204, 229,0.5)",
  thirdBrown: "rgb(51, 26, 0,0.5)",
  thirdLime: "rgb(0, 255, 0,0.5)",
};

export const check_date_within_range = (
  startDate: string,
  endDate: string,
  dataDate: string
) => {
  // Convert start and end dates to JavaScript Date objects
  const startDateObj = startDate ? new Date(startDate) : null;
  const endDateObj = endDate ? new Date(endDate) : null;

  // Convert the date from the data to a JavaScript Date object
  const data_date = new Date(dataDate);

  const isWithinDateRange =
    (!startDateObj || data_date >= startDateObj) &&
    (!endDateObj || data_date <= endDateObj);

  return isWithinDateRange;
};

export default function getLocaleName(locale: any) {
  if (!locale || !locale.name) return "";

  return locale.name.split("_")[1];
}
