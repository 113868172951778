import React from "react";
import { Form } from "react-bootstrap";

interface DateRangePickerProps {
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  return (
    <div>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="fw-bold mb-1">
            <small>Start Date:</small>
          </Form.Label>
          <Form.Control
            type="date"
            name="startDate"
            className="p-2"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            style={{ backgroundColor: "white" }}
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="exampleForm.ControlInput2">
          <Form.Label className="fw-bold mb-1">
            <span>
              <small>End Date:</small>
            </span>
          </Form.Label>
          <Form.Control
            type="date"
            name="endDate"
            className="p-2"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            style={{ backgroundColor: "white" }}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default DateRangePicker;
