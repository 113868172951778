import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Select from "react-select";
import { IdentifiedObjectItem } from "../../models/Media";
import { getIdentifiedObjects } from "../../services/Media";

interface IdentifiedObjectProps {
  mode: string;
  allIdentifiedObjects: IdentifiedObjectItem[];
  setAllIdentifiedObjects: Dispatch<SetStateAction<IdentifiedObjectItem[]>>;
  selectedIdentifiedObjects: IdentifiedObjectItem[];
  setSelectedIdentifiedObjects: Dispatch<
    SetStateAction<IdentifiedObjectItem[]>
  >;
}

const IdentifiedSpecies: React.FC<IdentifiedObjectProps> = ({
  mode,
  allIdentifiedObjects,
  setAllIdentifiedObjects,
  selectedIdentifiedObjects,
  setSelectedIdentifiedObjects,
}) => {
  const [selectedSpeciesOption, setSelectedSpeciesOption] = useState<
    { value: number; label: string }[]
  >([]);
  const [speciesOptions, setSpeciesOptions] = useState<
    { value: number; label: string }[]
  >([]);

  const mapSpeciesToOptions = (
    groups: IdentifiedObjectItem[]
  ): { value: number; label: string }[] => {
    return groups.map((group: IdentifiedObjectItem) => ({
      value: group.id,
      label: group.name,
    }));
  };

  const loadIdentifiedObjects = () => {
    getIdentifiedObjects().then((items: any) => {
      setAllIdentifiedObjects(items);
      const speciesOptions = mapSpeciesToOptions(items);
      setSpeciesOptions([
        { value: -1, label: "Select All" },
        ...speciesOptions,
      ]);
      if (speciesOptions.length > 0) {
        setSelectedSpeciesOption([speciesOptions[0]]); // Always set the first species as default
      }
    });
  };

  const loadSelectedIdentifiedObjects = () => {
    const ids = selectedSpeciesOption?.map((item) => item.value);

    let selectedObjects: IdentifiedObjectItem[] = [];
    if (ids)
      selectedObjects = allIdentifiedObjects?.filter((object) =>
        ids.includes(object.id)
      );
    setSelectedIdentifiedObjects(selectedObjects);
  };

  useEffect(() => {
    loadIdentifiedObjects();
  }, []);

  useEffect(() => {
    loadSelectedIdentifiedObjects();
  }, [selectedSpeciesOption]);

  const handleChange = (selectedOption: { value: number; label: string }[]) => {
    // If "Select All" is chosen, select all options
    if (selectedOption.some((option) => option.value === -1)) {
      setSelectedSpeciesOption(speciesOptions.slice(1)); // Exclude "Select All" itself
    } else {
      setSelectedSpeciesOption(selectedOption);
    }
  };

  const handleChangeSingle = (selectedOption: {
    value: number;
    label: string;
  }) => {
    setSelectedSpeciesOption([selectedOption]);
  };

  return (
    <>
      {mode === "multi" ? (
        <>
          <span className="d-block fw-bold mb-1">
            <small>Identified Species:</small>
          </span>
          <div>
            <Select
              inputId="species-report-select"
              isMulti={true}
              value={selectedSpeciesOption}
              options={speciesOptions}
              classNamePrefix="select"
              placeholder={"Choose Species"}
              onChange={(selectedOption) =>
                handleChange(
                  selectedOption as {
                    value: number;
                    label: string;
                  }[]
                )
              }
              styles={{
                control: (provided: any) => ({
                  ...provided,
                  borderColor: "#d1d7e0",
                  fontSize: 13,
                }),
                placeholder: (provided: any) => ({
                  ...provided,
                  color: "#506690",
                }),
                option: (provided: any) => ({
                  ...provided,
                  fontSize: 13,
                }),
              }}
            />
          </div>
        </>
      ) : (
        <Select
          isMulti={false}
          className={mode === "single" ? "basic-single" : ""}
          value={selectedSpeciesOption}
          options={speciesOptions}
          classNamePrefix="select"
          placeholder={"Choose Species"}
          onChange={(selectedOption) =>
            handleChangeSingle(
              selectedOption as {
                value: number;
                label: string;
              }
            )
          }
          styles={{
            control: (provided: any) => ({
              ...provided,
              backgroundColor: "#F5F8FB",
              borderColor: "#d1d7e0",
              fontSize: 13,
            }),
            placeholder: (provided: any) => ({
              ...provided,
              color: "#506690",
            }),
            option: (provided: any) => ({
              ...provided,
              fontSize: 13,
            }),
          }}
        />
      )}
    </>
  );
};

export default IdentifiedSpecies;
