import { Container, Row, Col } from "react-bootstrap";

const ProjectOverview: React.FC = () => {
    return (
        <section className="mt-5">
            <Container>
                <Row>
                    <Col md={3}>
                        <h4>Project Overview</h4>
                    </Col>
                    <Col md={9}>
                        <p>
                            From 1997-2018, an average of 356 koalas entered care facilities due to vehicle collisions each year. Mitigating koala fatalities and injuries caused by vehicles/trains along linear infrastructure is vital for koala conservation.This requires a deeper understanding and a better prediction of koala road crossing behavior, which relies on koala monitoring and tracking technology. Since 2021, Griffith University has developed and trialed an autonomous real-time artificial intelligence (AI) koala monitoring system, using a network of interconnected cameras to capture and process images of koalas.

                            <br /><br />
                            This project expands on this technology to cover wider areas of koala habitat in South East Queensland, and engage 10 local councils and community groups to facilitate the installation and maintenance of the camera network. This project will demonstrate the use of expandable AI for feasible remote koala monitoring that can improve koala management strategies in the South East Queensland region. This project is funded by the Community Sustainability Action Grant awarded by the Queensland Government's Department of Environment and Science.</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default ProjectOverview;
