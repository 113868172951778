import React, { createContext, useContext, useState } from "react";
import { RegionItem } from "../models/Region";

interface SelectedRegionContextProps {
    allRegions: RegionItem[] | null;
    setAllRegions: React.Dispatch<React.SetStateAction<RegionItem[] | null>>;
    selectedRegions: RegionItem[] | null;
    setSelectedRegions: React.Dispatch<React.SetStateAction<RegionItem[] | null>>;
  }
  
  export const SelectedRegionContext = createContext<SelectedRegionContextProps>({
    allRegions: null,
    setAllRegions: () => {},
    selectedRegions: null,
    setSelectedRegions: () => {},
  });
  

export const useSelectedRegionContext = () => useContext(SelectedRegionContext);

const SelectedRegionProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [selectedRegions, setSelectedRegions] = useState<RegionItem[] | null>(null);
  const [allRegions, setAllRegions] = useState<RegionItem[] | null>(null);


  return (
    <SelectedRegionContext.Provider value={{ allRegions, setAllRegions, selectedRegions, setSelectedRegions }}>
      {children}
    </SelectedRegionContext.Provider>
  );
};

export default SelectedRegionProvider;