import { Container, Form } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { postUser } from "../services/Users";
import { useEffect, useState } from "react";
import { PostUserItem } from "../models/User";
import { toast } from "react-toastify";
import { getGroups } from "../services/Groups";
import { GroupItem } from "../models/Group";
import SelectInput from "./SelectInput";
import ErrorAlertFor400 from "./ErrorAlertFor400";
import LabelAndInput from "./LabelAndInput";

const AddUser = ({ onClose }: { onClose: () => void }) => {
  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [APIErrors, setAPIErrors] = useState(null);
  const [groups, setGroups] = useState<
    {
      value: number;
      label: string;
    }[]
  >([]);

  const mapGroupsToOptions = (
    groups: GroupItem[]
  ): { value: number; label: string }[] => {
    return groups.map((group: GroupItem) => ({
      value: group.id,
      label: group.name,
    }));
  };

  const loadGroups = () => {
    const getGroups$ = getGroups({ page: "all", userGroups: false });
    const sub = getGroups$.subscribe({
      next: (res: any) => {
        const groupOptions = mapGroupsToOptions(res);
        setGroups(groupOptions);
      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    loadGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createGroup = (data: FieldValues) => {
    if (!data.email || data.selectedGroups.length === 0) {
      if (!data.email) {
        setError("email", { type: "focus" }, { shouldFocus: true });
      }

      if (data.selectedGroups.length === 0) {
        setError(
          "selectedGroups",
          { type: "focus", message: "Group selection is required" },
          { shouldFocus: true }
        );
      }
    } else {
      const toastId = toast.loading("Please wait...");
      const selectedGroupIds = data.selectedGroups?.map(
        (option: { value: any }) => option.value
      );

      const payload: PostUserItem = {
        username: data.email,
        email: data.email,
        group_ids: selectedGroupIds ? selectedGroupIds : [],
        first_name: data.firstName,
        last_name: data.lastName,
      };

      const postUser$ = postUser(payload);
      const sub = postUser$.subscribe({
        next: (res) => {
          toast.update(toastId, {
            render: "User has been created successfully.",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
            theme: "colored",
          });
          onClose();
        },
        error: (e) => {
          toast.update(toastId, {
            render: "Error! Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
          });
          if (e.status === 400) {
            setAPIErrors(e.response);
          }
          console.error("Error", e);
        },
      });
      return () => sub.unsubscribe();
    }
  };

  const onSubmit = (data: FieldValues) => {
    createGroup(data);
  };

  return (
    <Container>
      {APIErrors && <ErrorAlertFor400 APIErrorMessages={APIErrors} />}
      <div className="col-auto">
        <div className="row align-items-center mb-3 mt-3">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div className="col-md-5">
              <h4 className="mb-0">Add User</h4>
            </div>
            <div className="col-md-7 d-flex justify-content-end">
              {/* Cancel Button */}
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary mx-1 btn-sm btn-md-lg" // Unified button for all screen sizes
              >
                Cancel
              </button>

              {/* Save & Close Button */}
              <button
                type="button"
                onClick={handleSubmit(onSubmit)}
                className="btn btn-primary mx-1 btn-sm btn-md-lg" // Unified button for all screen sizes
              >
                Save & Close
              </button>
            </div>
          </div>
        </div>

        <hr />
        <div className="col-md-12  mb-4 mt-4">
          <Form className="mb-3">
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                    message: "Please enter a valid email address",
                  },
                })}
                isInvalid={errors.email !== undefined}
                isValid={!errors.email}
              />
              {errors?.email?.message && (
                <Form.Control.Feedback type="invalid">
                  {errors.email.message as string}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
          <LabelAndInput
            label="First name"
            register={register}
            registerName="firstName"
            readOnly={false}
          />
          <LabelAndInput
            label="Last name"
            register={register}
            registerName="lastName"
            readOnly={false}
          />
          {!groups.length && <div>No groups available for selection.</div>}
          {groups.length > 0 && (
            <SelectInput
              id={"user-select"}
              label="Group"
              isMulti={true}
              name="selectedGroups"
              control={control}
              options={groups}
              placeholder="Select group"
              fieldError={errors?.selectedGroups?.message ?? undefined}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default AddUser;
