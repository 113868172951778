import { Navigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { ReactNode } from "react";

interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  let auth = useAuth();

  if (auth.isAuthenticated) {
    return <>{children}</>;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to={"/"} replace />;
  }

  return null;
}

export default PrivateRoute;
