import { Col, Row, Spinner } from "react-bootstrap";
import PageLayout from "./PageLayout";
import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import Select from "react-select";
import { AllSpecies } from "./reports/AllSpecies";
import SpeciesByMonths from "./reports/SpeciesByMonths";
import SpeciesBySeasons from "./reports/SpeciesBySeasons";
import SpeciesByHour from "./reports/SpeciesByHour";
import DateRangePicker from "./DateRangePicker";
import IdentifiedSpeciesFilter from "./reports/IdentifiedSpeciesFilter";
import CameraFilter from "./reports/CameraFilter";
import { CameraItem } from "../models/Camera";
import { IdentifiedObjectItem, IdentifiedSpeciesCount } from "../models/Media";
import { getIdentifiedObjectsCountByCameras } from "../services/Media";
import DateYearPicker from "./DateYearPicker";
import { Value } from "react-multi-date-picker";
import SpeciesOverMonthByYear from "./reports/SpeciesOverMonthByYear";
import SpeciesByYear from "./reports/SpeciesByYear";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const chartOptions = [
  {
    value: "AllSpecies",
    label: " All Species Comparison - (Chart 01)",
  },
  {
    value: "SpeciesByMonths",
    label: "Species Over Months (Chart 02)",
  },
  {
    value: "SpeciesBySeasons",
    label: "Species Over Seasons (Chart 03)",
  },
  {
    value: "SpeciesTimeOfHour",
    label: "Species Over Time of Hour (Chart 04)",
  },
  {
    value: "SpeciesOverMonthByYear",
    label: "Species Over Months by Year (Chart 05)",
  },
  {
    value: "SpeciesOverTimeofYear",
    label: "Species Over Time of Year (Chart 06)",
  },
];

const Reports = () => {
  const [selectedChart, setSelectedChart] = useState<{
    value: string;
    label: string;
  }>(chartOptions[0]);
  const [selectedCameras, setSelectedCameras] = useState<CameraItem[]>([]);
  const [allCameras, setAllCameras] = useState<CameraItem[]>([]);
  const cameras = selectedCameras || allCameras;

  const [selectedIdentifiedObjects, setSelectedIdentifiedObjects] = useState<
    IdentifiedObjectItem[]
  >([]);
  const [allIdentifiedObjects, setAllIdentifiedObjects] = useState<
    IdentifiedObjectItem[]
  >([]);
  const selectedSpecies = selectedIdentifiedObjects || allIdentifiedObjects;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [yearNames, setYearNames] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<Value>(Date.now()); // Set the value of Datepicker to have a default selected value

  const [reportData, setReportData] = useState<IdentifiedSpeciesCount[]>([]);

  const [loading, setLoading] = useState(false);
  const renderChart = (data: IdentifiedSpeciesCount[]) => {
    switch (selectedChart?.value) {
      case "AllSpecies":
        return (
          <AllSpecies
            cameras={cameras}
            selectedCameras={selectedCameras}
            allIdentifiedObjects={allIdentifiedObjects}
            selectedIdentifiedObjects={selectedIdentifiedObjects}
            reportData={reportData}
            startDate={startDate}
            endDate={endDate}
          />
        );
      // case "LocationDetectedSpeciesComparison":
      //   return <LocationDetectedSpecies />;
      case "SpeciesByMonths":
        return (
          <SpeciesByMonths
            cameras={cameras}
            selectedCameras={selectedCameras}
            allIdentifiedObjects={allIdentifiedObjects}
            selectedIdentifiedObjects={selectedIdentifiedObjects}
            reportData={reportData}
            startDate={startDate}
            endDate={endDate}
          />
        );

      case "SpeciesBySeasons":
        return (
          <SpeciesBySeasons
            cameras={cameras}
            selectedCameras={selectedCameras}
            allIdentifiedObjects={allIdentifiedObjects}
            selectedIdentifiedObjects={selectedIdentifiedObjects}
            reportData={reportData}
            startDate={startDate}
            endDate={endDate}
          />
        );
      case "SpeciesTimeOfHour":
        return (
          <SpeciesByHour
            cameras={cameras}
            selectedCameras={selectedCameras}
            allIdentifiedObjects={allIdentifiedObjects}
            selectedIdentifiedObjects={selectedIdentifiedObjects}
            reportData={reportData}
            startDate={startDate}
            endDate={endDate}
          />
        );
      case "SpeciesOverMonthByYear":
        return (
          <SpeciesOverMonthByYear
            cameras={cameras}
            selectedCameras={selectedCameras}
            allIdentifiedObjects={allIdentifiedObjects}
            selectedIdentifiedObjects={selectedIdentifiedObjects}
            reportData={reportData}
            yearNames={yearNames}
          />
        );
      case "SpeciesOverTimeofYear":
        return (
          <SpeciesByYear
            cameras={cameras}
            selectedCameras={selectedCameras}
            allIdentifiedObjects={allIdentifiedObjects}
            selectedIdentifiedObjects={selectedIdentifiedObjects}
            reportData={reportData}
            yearNames={yearNames}
          />
        );
      // case "AllSpeciesByLocation":
      //   return <AllSpeciesByLocation />;
      default:
        return null;
    }
  };

  const handleChange = (selectedOption: { value: string; label: string }) => {
    setSelectedChart(selectedOption);
  };
  const getReportData = () => {
    if (cameras.length > 0) {
      console.log("selectedCamera", cameras);
      setLoading(true); // Set loading to true before fetching
      let allData: IdentifiedSpeciesCount[] = [];
      let page = 1;

      const fetchPage = (page: number) => {
        const getReportData$ = getIdentifiedObjectsCountByCameras({
          camera_ids: cameras.map((camera) => camera.camera_id),
          species_ids: selectedIdentifiedObjects.map((object) => object.id),
          page: page,
        });

        const sub = getReportData$.subscribe({
          next: (res: any) => {
            allData = [...allData, ...res.results];
            console.log(`Page ${page} results:`, res.results);

            if (res.next) {
              fetchPage(page + 1);
            } else {
              setReportData(allData);
              setLoading(false);
              console.log("All data fetched:", allData);
            }
          },
          error: (err: any) => {
            console.error("Error fetching report data:", err);
            setLoading(false);
          },
        });

        return () => sub.unsubscribe();
      };

      fetchPage(page); // Start fetching from the first page
    }
  };

  useEffect(() => {
    getReportData();
  }, [selectedCameras, selectedSpecies, startDate, endDate]);

  return (
    <PageLayout>
      Reports
      <Row className="mt-1">
        <Select
          isMulti={false}
          options={chartOptions}
          classNamePrefix="select"
          value={selectedChart}
          onChange={(selectedOption) =>
            handleChange(
              selectedOption as {
                value: string;
                label: string;
              }
            )
          }
          styles={{
            control: (provided) => ({
              ...provided,
              // backgroundColor: "#F5F8FB",
              borderColor: "#d1d7e0",
            }),
            placeholder: (provided) => ({
              ...provided,
              color: "#506690",
            }),
          }}
        />
        <Row>
          <Col md={4} xl={4} lg={5} sm={12} xs={12} className="mt-4">
            <Row className="mt-3">
              <CameraFilter
                mode={"multi"}
                allCameras={allCameras}
                setAllCameras={setAllCameras}
                selectedCameras={selectedCameras}
                setSelectedCameras={setSelectedCameras}
              />
            </Row>

            <Row className="mt-3">
              <IdentifiedSpeciesFilter
                mode={"multi"}
                allIdentifiedObjects={allIdentifiedObjects}
                setAllIdentifiedObjects={setAllIdentifiedObjects}
                selectedIdentifiedObjects={selectedIdentifiedObjects}
                setSelectedIdentifiedObjects={setSelectedIdentifiedObjects}
              />
            </Row>
            {selectedChart.value !== "SpeciesOverMonthByYear" &&
              selectedChart.value !== "SpeciesOverTimeofYear" && (
                <Row className="mt-3">
                  <DateRangePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Row>
              )}
            {selectedChart.value === "SpeciesOverMonthByYear" && (
              <Row className="mt-3">
                <DateYearPicker
                  yearNames={yearNames && selectedYear}
                  setYearNames={setYearNames}
                />
              </Row>
            )}
            {selectedChart.value === "SpeciesOverTimeofYear" && (
              <Row className="mt-3">
                <DateYearPicker
                  yearNames={yearNames && selectedYear}
                  setYearNames={setYearNames}
                />
              </Row>
            )}
          </Col>
          <Col md={8} xl={8} lg={7} sm={12} xs={12} className="mt-4 p-0">
            <div style={{ position: "relative" }}>
              <div style={{ opacity: loading ? 0.5 : 1 }}>
                {renderChart(reportData)}
              </div>
              {loading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(255,255,255,0.7)",
                  }}
                >
                  <Spinner animation="border" role="status" />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Row>
    </PageLayout>
  );
};

export default Reports;
