import React from "react";
import { Container } from "react-bootstrap";

const JumbotronPanel: React.FC = () => {
  return (
    <React.Fragment>
      <section>
        <Container fluid className="jumbotron-image">
          <div className="overlay">
            <h1 className="jumbotron-title py-2">
              Community Facilitated Large-Scale AI-Powered Koala Road Safety
              Monitoring Network
            </h1>
          </div>
          <div className="red-strip"></div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JumbotronPanel;
