import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NotificationItem } from "../models/Notification";
import { getNotifications } from "../services/Notification";
import { catchError, expand, from, of, reduce, switchMap } from "rxjs";

interface NotificationContextProps {
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  notificationItem: NotificationItem[] | null;
  setNotificationItem: (notificationItem: NotificationItem[] | null) => void;
  fetchNotificationItem: (options: {
    is_read: boolean;
    page: number | "all";
    groupId?: number[];
  }) => void;
  totalItems: number;
  unreadCount: number;
  fetchUnreadCount: (options?: { groupId?: number[] }) => void;
}

export const NotificationItemsContext =
  createContext<NotificationContextProps | null>(null);

export const useNotificationItemsContext = () =>
  useContext(NotificationItemsContext)!;

const NotificationItemsProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [notificationItem, setNotificationItem] = useState<
    NotificationItem[] | null
  >([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [unreadCount, setUnreadCount] = useState<number>(0);

  const fetchNotificationItem = useCallback(
    (options: {
      page?: number | "all";
      is_read: boolean;
      camera_ids?: number[]; // Optional camera filter
    }) => {
      if (options.page === "all") {
        // If "all" pages, we'll need to fetch all pages iteratively
        let currentPage = 1;

        const allNotifications$ = from([currentPage]).pipe(
          // Switch to the fetch call for the current page
          switchMap(() =>
            getNotifications({
              page: currentPage,
              is_read: options.is_read,
              camera_ids: options.camera_ids, // Include camera_ids filter here
            }).pipe(
              // Continue fetching pages until we've fetched all pages
              expand((data) => {
                const totalPages = Math.ceil(data.count / data.results.length);
                setTotalItems(data.count); // Set total items here
                if (currentPage < totalPages) {
                  currentPage++;
                  return getNotifications({
                    page: currentPage,
                    is_read: options.is_read,
                    camera_ids: options.camera_ids, // Include camera_ids filter here
                  });
                } else {
                  return of(null); // Emit null when all pages have been fetched
                }
              }),
              // Accumulate all notification results
              reduce((acc: NotificationItem[], currentPageData) => {
                if (currentPageData) {
                  return [...acc, ...currentPageData.results];
                }
                return acc;
              }, [])
            )
          ),
          catchError((error) => {
            console.error(error);
            return of([]); // Fallback for error case
          })
        );

        // Subscribe and update state with the notifications
        allNotifications$.subscribe((notifications: NotificationItem[]) => {
          setNotificationItem(notifications);
        });
      } else {
        // Fetch notifications for a single page
        getNotifications({
          page: options.page,
          is_read: options.is_read,
          camera_ids: options.camera_ids, // Include camera_ids filter here
        })
          .pipe(
            catchError((error) => {
              console.error(error);
              return of([]); // Handle error and return an empty array
            })
          )
          .subscribe((data: any) => {
            setNotificationItem(data.results); // Update state with notification data
            setTotalItems(data.count); // Set total items for a single page fetch
          });
      }
    },
    []
  );

  const fetchUnreadCount = useCallback(() => {
    const queryParams = {
      page: undefined, // No pagination for this request
      is_read: false, // Only unread notifications
    };

    const getNotifications$ = getNotifications(queryParams);

    const sub = getNotifications$.subscribe({
      next: (res) => {
        console.log("counts from here", res.count);
        setUnreadCount(res.count); // Update unread count state with the response
      },
      error: (error) => {
        console.error("Failed to fetch unread count", error);
      },
    });

    // Cleanup subscription on unmount
    return () => sub.unsubscribe();
  }, []);
  // Timer to periodically fetch notifications and unread counts
  useEffect(() => {
    const interval = setInterval(() => {
      fetchUnreadCount();
    }, 30000); // Every 30 seconds

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, [fetchUnreadCount]);

  const values = useMemo(
    () => ({
      showAlert,
      setShowAlert,
      notificationItem,
      fetchNotificationItem,
      setNotificationItem,
      totalItems,
      unreadCount,
      fetchUnreadCount,
    }),
    [
      notificationItem,
      fetchNotificationItem,
      showAlert,
      setShowAlert,
      totalItems,
      unreadCount,
      fetchUnreadCount,
    ]
  );
  return (
    <NotificationItemsContext.Provider value={values}>
      {children}
    </NotificationItemsContext.Provider>
  );
};

export default NotificationItemsProvider;
