import { Button } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { useRef, useCallback } from "react";
import { CSVLink } from "react-csv";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import DataLabels plugin
import { Chart, registerables } from "chart.js";

// Register the Chart.js components and plugins
Chart.register(...registerables);
Chart.register(ChartDataLabels); // Register the DataLabels plugin

interface BarChartProps {
  mapData: any;
  options: any;
  csvData: any;
  name: string;
}

const BarChart: React.FC<BarChartProps> = ({
  mapData,
  options,
  csvData,
  name,
}) => {
  const fileName = name + ".csv";
  let ref = useRef<any>(null);
  const handleClick = useCallback(() => {
    const link = document.createElement("a");
    link.download = name + ".png";
    if (ref.current) link.href = ref.current.toBase64Image();
    link.click();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("map data from barchart", mapData);

  // Define options for displaying datalabels on the bars
  const chartOptions = {
    ...options,
    plugins: {
      ...options.plugins,
      datalabels: {
        display: true, // Show data labels
        color: "black", // Label color
        anchor: "end",
        align: "top",
        rotation: (context: any) => {
          const object = context.dataset.data[context.dataIndex].object;
          return object && 90;
        },
        font: {
          weight: "bold",
          size: 11,
        },
        formatter: (value: any) => {
          const object = value?.object;

          if (object) {
            if (object.count > 0) {
              return `${object.name}: ${object.count}`;
            } else {
              return null;
            }
          }

          return value;
        },
      },
    },
  };

  // Render the chart if data is available, otherwise display a message
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
          marginRight: "-10px",
          gap: "10px",
        }}
      >
        <div>
          {/* CSV Export Button */}
          {csvData && (
            <CSVLink
              filename={fileName}
              data={csvData}
              className="btn btn-success btn-sm"
            >
              Export to CSV
            </CSVLink>
          )}
        </div>
        <div>
          {/* PNG Export Button */}
          <Button variant="danger" size="sm" onClick={handleClick}>
            Export Chart to PNG
          </Button>
        </div>
      </div>

      {/* Bar Chart Container */}
      <div
        style={{
          backgroundColor: "white",
          padding: "15px",
          marginRight: "-10px",
          height: "97%",
        }}
      >
        {mapData && mapData?.datasets?.length > 0 ? (
          <Bar ref={ref} options={chartOptions} data={mapData} />
        ) : (
          <div className="m-3">No Data to display.</div>
        )}
      </div>
    </>
  );
};

export default BarChart;
