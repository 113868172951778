import { Container } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { getUsers } from "../services/Users";
import { useEffect, useState } from "react";
import { User } from "../models/User";
import { toast } from "react-toastify";
import { editGroup, postGroup } from "../services/Groups";
import { EditGroupItem, PostGroupItem } from "../models/Group";
import SelectInput from "./SelectInput";
import LabelAndInput from "./LabelAndInput";
import ErrorAlertFor400 from "./ErrorAlertFor400";

const AddEditGroup = ({
  onClose,
  formData,
}: // visibility,
{
  onClose: () => void;
  formData?: any;
  // visibility: boolean;
}) => {
  const [users, setUsers] = useState<
    {
      value: number;
      label: string;
    }[]
  >([]);
  const { control, register, handleSubmit, setValue, setError } = useForm();
  const [APIErrors, setAPIErrors] = useState(null);
  const [loading, setLoading] = useState(true);

  const mapUsersToOptions = (
    users: User[]
  ): { value: number; label: string }[] => {
    return users.map((user: User) => ({
      value: user.id,
      label: `${user.first_name} ${user.last_name} ${user.username}`,
    }));
  };

  useEffect(() => {
    if (formData) {
      const users = formData.users;
      const userOptions = mapUsersToOptions(users);
      setValue("selectedUsers", userOptions);
      setValue("name", formData?.name);
    }
  }, [formData, setValue]);

  const loadUsers = () => {
    const getUsers$ = getUsers("all");
    const sub = getUsers$.subscribe({
      next: (res: any) => {
        const userOptions = mapUsersToOptions(res);
        setUsers(userOptions);
        setLoading(false);
      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    setLoading(true);
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateGroup = (data: FieldValues) => {
    if (!data.name) {
      setError("name", { type: "focus" }, { shouldFocus: true });
    } else {
      const toastId = toast.loading("Please wait...");
      const selectedUserIds = data.selectedUsers?.map(
        (option: { value: any }) => option.value
      );
      const payload: EditGroupItem = {
        id: data.id,
        name: data.name,
        users: selectedUserIds ? selectedUserIds : [],
        groupprop: {
          id: formData.groupprop.id,
          group: data.id,
          is_visible: formData.groupprop.is_visible,
        },
      };

      const editGroup$ = editGroup(formData.id, payload);
      const sub = editGroup$.subscribe({
        next: (res) => {
          toast.update(toastId, {
            render: "Group has been updated successfully.",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
            theme: "colored",
          });
          onClose();
        },
        error: (e) => {
          toast.update(toastId, {
            render: "Error! Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
          });
          if (e.status === 400) {
            setAPIErrors(e.response);
          }
          console.error("Error", e);
        },
      });
      return () => sub.unsubscribe();
    }
  };

  const createGroup = (data: FieldValues) => {
    if (!data.name) {
      setError("name", { type: "focus" }, { shouldFocus: true });
    } else {
      const toastId = toast.loading("Please wait...");
      const selectedUserIds = data.selectedUsers?.map(
        (option: { value: any }) => option.value
      );
      const payload: PostGroupItem = {
        name: data.name,
        users: selectedUserIds ? selectedUserIds : [],
      };

      const postGroup$ = postGroup(payload);
      const sub = postGroup$.subscribe({
        next: (res) => {
          toast.update(toastId, {
            render: "Group has been created successfully.",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
            theme: "colored",
          });
          onClose();
        },
        error: (e) => {
          toast.update(toastId, {
            render: "Error! Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            hideProgressBar: false,
          });
          if (e.status === 400) {
            setAPIErrors(e.response);
          }
          console.error("Error", e);
        },
      });
      return () => sub.unsubscribe();
    }
  };

  const onSubmit = (data: FieldValues) => {
    formData ? updateGroup(data) : createGroup(data);
  };
  if (loading) {
    return <div>Loading ...</div>;
  }
  return (
    <Container>
      {APIErrors && <ErrorAlertFor400 APIErrorMessages={APIErrors} />}
      <div className="col-auto">
        <div className="row align-items-center mb-3 mt-3">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div className="col-md-5">
              <h4 className="mb-0">{formData ? "Edit Group" : "Add Group"}</h4>
            </div>
            <div className="col-md-7 d-flex justify-content-end">
              {/* Cancel Button */}
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary mx-1 btn-sm btn-md-lg" // Unified button for all screen sizes
              >
                Cancel
              </button>

              {/* Submit Button */}
              <button
                type="button"
                onClick={handleSubmit(onSubmit)}
                className="btn btn-primary mx-1 btn-sm btn-md-lg" // Unified button for all screen sizes
              >
                {formData ? "Update" : "Save & Close"}
              </button>
            </div>
          </div>
        </div>

        <hr />

        <div className="col-md-12 mb-4 mt-4">
          {formData ? (
            <LabelAndInput
              label="Group name"
              register={register}
              registerName="name"
              rules={{ required: "This field is required" }}
              handleFocus={() => setAPIErrors(null)}
              readOnly={true}
            />
          ) : (
            <LabelAndInput
              label="Group name"
              register={register}
              registerName="name"
              rules={{ required: "This field is required" }}
              handleFocus={() => setAPIErrors(null)}
              readOnly={false}
            />
          )}

          {!users.length && !formData?.users.length && (
            <div>No users available for selection.</div>
          )}
          {(users.length > 0 || formData?.users.length > 0) && (
            <SelectInput
              id={"user-group-select"}
              label="Users"
              isMulti
              name="selectedUsers"
              control={control}
              options={users}
              placeholder="Select users"
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default AddEditGroup;
