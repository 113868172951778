import React from "react";
import { Alert } from "react-bootstrap";

interface ErrorAlertProps {
  APIErrorMessages: Record<string, any>;
}

const ErrorAlertFor400: React.FC<ErrorAlertProps> = ({ APIErrorMessages }) => {
  const errorMessages: string[] = [];

  const flatten = (obj: Record<string, any>, prefix = "") => {
    Object.entries(obj).forEach(([key, val]) => {
      if (typeof val === "object") {
        flatten(val, `${prefix}${key}: `);
      } else {
        errorMessages.push(`${prefix}${val}`);
      }
    });
  };

  flatten(APIErrorMessages);

  return (
    <Alert variant="danger">
      <i className="bi bi-exclamation-triangle-fill me-2 flex-shrink-0"></i>
      <span>
        <b>Please fix the following errors:</b>
      </span>
      <div className="d-flex align-items-center">
        <ul className="list-group ms-4">
          {errorMessages.map((message, index) => (
            <li key={index}>{capitalizeFieldName(message.replace(/['{}]/g, ""))}</li>
          ))}
        </ul>
      </div>
    </Alert>
  );
};

const capitalizeFieldName = (message: string) => {
  const parts = message.split(": ");
  if (parts.length === 2) {
    const fieldName = parts[0];
    const errorMessage = parts[1];
    return `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}: ${errorMessage}`;
  }
  return message;
};

export default ErrorAlertFor400;
