import { Container, Row, Col } from "react-bootstrap";

const PrivacyPlan: React.FC = () => {
    return (
        <section className="pt-5 pb-3 mt-3 privacy-plan">
            <Container>
                <Row>
                    <Col md={3}>
                        <h4>Privacy Plan</h4>
                    </Col>
                    <Col md={9}>
                        <p>
                            The wildlife monitoring cameras may record footage of people within this area. Griffith University uses the cameras for the purpose of Research of LOCAL KOALA POPULATIONS. The personal information collected may include your image, vehicle registration and other information that may identify you. For more information about how Griffith University handles your personal information, please read through the Privacy Plan.
                            <br /><br />
                            <a href="https://www.griffith.edu.au/about-griffith/corporate-governance/plans-publications/griffith-university-privacy-plan" target="_blank">Griffith's Privacy Plan</a>

                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default PrivacyPlan;
