import JumbotronPanel from '../components/JumbotronPanel';
import TopNavbar from '../components/TopNavbar';
import ProjectOverview from '../components/ProjectOverview';
import PrivacyPlan from '../components/PrivacyPlan';

const Home = () => {
    return (
        <>
            <TopNavbar />
            <JumbotronPanel />
            <ProjectOverview />
            <PrivacyPlan />
        </>

    );
};

export default Home;
