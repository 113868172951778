import { createContext } from "react";

interface SignInContextType {
  isAdminUser: boolean;
  userGroupsCount: number | null
}

const initialState: SignInContextType = {
  isAdminUser: false,
  userGroupsCount: null
};

export const SignInContext = createContext(initialState);
