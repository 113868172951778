import React from 'react';
import { Spinner as ReactSpinner } from 'react-bootstrap';

const Spinner: React.FC = () => {
  return (
    <div className="spinner text-center">
      <ReactSpinner animation="border" />
    </div>
  );
};

export default Spinner;
