import React, { createContext, useContext, useState } from "react";
import { MediaItem } from "../models/Media";

interface SelectedMediaContextProps {
    selectedMedia: MediaItem | null;
    setSelectedMedia: React.Dispatch<React.SetStateAction<MediaItem | null>>;
  }
  
  export const SelectedMediaContext = createContext<SelectedMediaContextProps>({
    selectedMedia: null,
    setSelectedMedia: () => {},
  });
  

export const useSelectedMediaContext = () => useContext(SelectedMediaContext);

const SelectedMediaProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [selectedMedia, setSelectedMedia] = useState<MediaItem | null>(null);
  
  return (
    <SelectedMediaContext.Provider value={{ selectedMedia, setSelectedMedia }}>
      {children}
    </SelectedMediaContext.Provider>
  );
};

export default SelectedMediaProvider;