import { expand, Observable, reduce } from "rxjs";
import { baseURL } from "./ServiceUtil";
import { PostCameraItem } from "../models/Camera";
import { customAjax } from "../components/ErrorHandler";

// export const getCameras = (options?: {
//   page?: number | "all";
//   group?: number;
// }): Promise<any> => {
//   let queryParams = "";

//   // Add logic to include page parameter
//   if (options?.page) {
//     queryParams += `${queryParams ? "&" : ""}page_size=${options?.page}`;
//   }

//   // Add logic to include group_visibility parameter
//   if (options?.group) {
//     queryParams += `group=${options.group}`;
//   }

//   // const url = `${baseURL}/groups${queryParams ? "?" + queryParams : ""}`;
//   const url = `${baseURL}/cameras`;
//   return fetchData(url, queryParams);
// };
export const getCameras = (options?: {
  page?: number | "all";
  group?: number;
  search?: string;
  pageSize?: number;
}): Observable<any> => {
  let queryParams = "";

  if (options?.page !== undefined && options.page !== null) {
    if (options.page === "all") {
      queryParams = new URLSearchParams({ page_size: "all" }).toString();
    } else {
      queryParams = new URLSearchParams({
        page: options.page.toString(),
      }).toString();
    }
  }
  // if there is a search query , we don;t need to send the page paramater
  if (options?.search) {
    // queryParams += `${queryParams ? "&" : ""}camera_id=${options?.search}`;
    queryParams = `${queryParams ? "&" : ""}camera_id=${encodeURIComponent(
      options.search
    )}`;
  }

  if (options?.group) {
    queryParams += `${queryParams ? "&" : ""}group=${options.group.toString()}`;
  }
  if (options?.pageSize) {
    queryParams += `${queryParams ? "&" : ""}page_size=${options.pageSize}`;
  }

  const url = `${baseURL}/cameras${queryParams ? `?${queryParams}` : ""}`;

  return customAjax(url);
};

export const updateCamera = (cameraPayload: PostCameraItem) =>
  customAjax(`${baseURL}/cameras/${cameraPayload.id}`, "PUT", cameraPayload);

export const getCameraById = (id?: number): Observable<any> => {
  // Check if 'id' is defined before adding it to the URL
  const idQueryParam = id ? `id=${id}` : "";

  return customAjax(
    `${baseURL}/cameras${idQueryParam ? `?${idQueryParam}` : ""}`
  );
};
// Helper function to handle API request
const fetchPage = (url: string): Observable<any> => {
  return customAjax(url);
};

export const getCameraFilters = (): Observable<any> => {
  // Start by fetching the first page
  return fetchPage(`${baseURL}/cameras_filter`).pipe(
    // Use `expand` to recursively fetch the next pages
    expand((response: any) => {
      // If `next` is defined, fetch the next page, otherwise return an empty observable
      return response.next ? fetchPage(response.next) : [];
    }),
    // Collect all results into an array using `reduce`
    reduce((acc: any[], response: any) => acc.concat(response.results), [])
  );
};

export const postCamera = (cameraPayload: PostCameraItem) =>
  customAjax(`${baseURL}/cameras`, "POST", cameraPayload);
