import React from "react";
import { Form } from "react-bootstrap";
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";

interface LabelAndInputProps {
  label: string;
  register: UseFormRegister<any>;
  rules?: RegisterOptions;
  registerName: string;
  handleFocus?: () => void;
  labelClassName?: string;
  fieldError?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  readOnly: boolean;
}

const LabelAndInput: React.FC<LabelAndInputProps> = ({
  label,
  register,
  rules,
  registerName,
  handleFocus,
  labelClassName,
  fieldError,
  readOnly,
}) => {
  return (
    <div className="mb-3">
      <Form.Label className={labelClassName}>{label}</Form.Label>
      <Form.Control
        type="text"
        {...register(registerName, rules)}
        placeholder={`Enter ${label.toLowerCase()}`}
        onFocus={handleFocus}
        readOnly={readOnly}
        className={readOnly ? "read-only-style" : ""} // Apply a read-only class
        style={
          readOnly ? { backgroundColor: "#e9ecef", cursor: "not-allowed" } : {}
        }
      />
      {fieldError && (
        <span className="text-danger" data-testid="error-message">
          {fieldError as string}
        </span>
      )}
    </div>
  );
};

export default LabelAndInput;
