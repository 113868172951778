import { baseURL } from "./ServiceUtil";
import {
  EditGroupItem,
  EditGroupPropItem,
  PostGroupItem,
} from "../models/Group";
import { customAjax } from "../components/ErrorHandler";
import { Observable } from "rxjs";

export const getGroups = (options?: {
  userGroups?: boolean;
  visibility?: boolean;
  page?: number | string;
  pageSize?: number;
  search?: string;
}): Observable<any> => {
  let queryParams = "";

  if (options?.page !== undefined && options?.page !== null) {
    if (options?.page === "all") {
      queryParams = new URLSearchParams({ page_size: "all" }).toString();
    } else {
      queryParams = new URLSearchParams({
        page: options?.page.toString(),
      }).toString();
    }
  }

  // if there is a search query , we don;t need to send the page paramater
  if (options?.search) {
    queryParams = `${queryParams ? "&" : ""}search=${encodeURIComponent(
      options?.search
    )}`;
  }
  // Add logic to include user_groups parameter
  if (options?.userGroups) {
    queryParams += `${queryParams ? "&" : ""}user_groups=${
      options?.userGroups
    }`;
  }

  // Add logic to include group_visibility parameter
  if (options?.visibility) {
    queryParams += `&group_visibility=${options.visibility}`;
  }

  // Add logic to include pageSize parameter
  if (options?.pageSize) {
    queryParams += `${queryParams ? "&" : ""}page_size=${options?.pageSize}`;
  }
  const url = `${baseURL}/groups${queryParams ? "?" + queryParams : ""}`;
  // const url = `${baseURL}/groups`;
  return customAjax(url);
  // return fetchData(url, queryParams);
};

export const postGroup = (groupPayload: PostGroupItem) =>
  customAjax(`${baseURL}/groups`, "POST", groupPayload);

export const editGroup = (groupId: number, groupPayload: EditGroupItem) =>
  customAjax(`${baseURL}/groups/${groupId}`, "PUT", groupPayload);

export const editGroupProp = (
  groupId: number,
  groupPayload: EditGroupPropItem
) => {
  console.log("call to", `${baseURL}/group_properties/${groupId}`);
  return customAjax(
    `${baseURL}/group_properties/${groupId}`,
    "PUT",
    groupPayload
  );
};

// export const editGroupVisibility = (
//   groupId: number,
//   groupPayload: EditVisibiltyGroupItem
// ) => customAjax(`${baseURL}/groups/${groupId}`, "PUT", groupPayload);
