import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import griffithLogo from "../assets/images/griffithLogo.jpg";
import UserMenu from "./UserMenu";

const TopNavbar = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className="px-xl-5">
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            src={griffithLogo}
            className="d-inline-block align-top"
            alt="AI for Koala"
            height={38}
          />
          <span className="ms-3">AI for Koala</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav justify-content-end">
          <Nav className="me-auto"></Nav>
          <UserMenu />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopNavbar;
