// ajaxInterceptor.ts

import { ajax, AjaxError } from "rxjs/ajax";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { getHeaders } from "../services/ServiceUtil";

const errorHandler = (error: AjaxError) => {
  // Check if the error has a 500 status code
  if (error.status === 500) {
    // Global error handling logic for 500 errors
    console.error("Global 500 error:", error);
    window.location.href = "/internal-server-error";
  }

  // For other errors, let them propagate for local handling
  return throwError(() => error);
};

// Create a custom ajax function with global error handling
export const customAjax = (
  url: string,
  method: string = "GET",
  payload?: any
) => {
  return ajax({ url, method, body: payload, headers: getHeaders() }).pipe(
    catchError((error: AjaxError) => errorHandler(error)),
    map((response) => response.response as any)
  );
};
