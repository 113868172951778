
import { Row } from "react-bootstrap";
import PageLayout from "./PageLayout";

const InternalServerError = () => {
    return (
        <PageLayout title="Internal Server Error">
            <Row className="mx-1">Either server cannot be reached or encountered an error and could not complete your request.</Row>
        </PageLayout>
    );
};

export default InternalServerError;
