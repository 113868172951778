import { Route, Routes, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import Dashboard from "./pages/Dashboard";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/Home";
import AuthEvents from "./AuthEvents";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "react-oidc-context";
import ManageGroups from "./pages/ManageGroups";
import "bootstrap-icons/font/bootstrap-icons.css";
import Footer from "./components/Footer";
import { whoAmI } from "./services/WhoAmI";
import { SignInContext } from "./context/SignInContext";
import AdminRoute from "./components/AdminRoute";
import ManageCameras from "./pages/ManageCameras";
import InternalServerError from "./components/InternalServerError";
import NotificationItemsProvider from "./context/NotificationContext";
import Reports from "./components/Reports";

initializeIcons();

function App() {
  const auth = useAuth();
  const [authInitialising, setAuthInitialising] = useState(true);
  const [isAdminUser, setIsAdminUser] = useState<boolean>(false);
  const [userGroupsCount, setUserGroupsCount] = useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (authInitialising && !auth.isLoading) {
      setAuthInitialising(false);
    }
  }, [auth.isLoading, authInitialising, setAuthInitialising]);

  useEffect(() => {
    if (auth.isAuthenticated && auth.user) {
      const whoAmI$ = whoAmI();
      const sub = whoAmI$.subscribe({
        next: (res: any) => {
          setUserGroupsCount(res.user_groups_count);
          setIsAdminUser(res.found);
        },
        error: (e) => {
          if (e.status === 500) {
            navigate("/internal-server-error");
          }
          console.error("Error:", e);
        },
      });
      return () => sub.unsubscribe();
    }
  }, [auth, authInitialising]);

  const signInContextValue = useMemo(
    () => ({ isAdminUser, userGroupsCount }),
    [isAdminUser, userGroupsCount]
  );

  return (
    <NotificationItemsProvider>
      <SignInContext.Provider value={signInContextValue}>
        <div className="wrapper">
          <main>
            <AuthEvents />
            {authInitialising ? null : (
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/manage-groups"
                  element={
                    <AdminRoute>
                      <ManageGroups />
                    </AdminRoute>
                  }
                />
                {/* <Route
                  path="/active-notifications"
                  element={
                    <PrivateRoute>
                      <OffCanvas />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/past-notifications"
                  element={
                    <PrivateRoute>
                      <ViewNotifications />
                    </PrivateRoute>
                  }
                /> */}
                <Route
                  path="/manage-cameras"
                  element={
                    <PrivateRoute>
                      <ManageCameras />
                    </PrivateRoute>
                  }
                />
                {/* hiding the reports for now, until we replacing the way of retrieving identifiedSpeciesCount */}
                <Route
                  path="/reports"
                  element={
                    <PrivateRoute>
                      <Reports />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/internal-server-error"
                  element={<InternalServerError />}
                />
              </Routes>
            )}
            <ToastContainer />
          </main>
          <Footer />
        </div>
      </SignInContext.Provider>
    </NotificationItemsProvider>
  );
}

export default App;
