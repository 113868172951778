import React, { createContext, useContext, useState } from "react";
import { IdentifiedObjectItem } from "../models/Media";

interface SelectedIdentifiedObjectContextProps {
  allIdentifiedObjects: IdentifiedObjectItem[] | null;
  setAllIdentifiedObjects: React.Dispatch<React.SetStateAction<IdentifiedObjectItem[] | null>>;
  selectedIdentifiedObjects: IdentifiedObjectItem[] | null;
  setSelectedIdentifiedObjects: React.Dispatch<React.SetStateAction<IdentifiedObjectItem[] | null>>;
}

export const SelectedIdentifiedObjectContext = createContext<SelectedIdentifiedObjectContextProps>({
  allIdentifiedObjects: null,
  setAllIdentifiedObjects: () => { },
  selectedIdentifiedObjects: null,
  setSelectedIdentifiedObjects: () => { },
});


export const useSelectedIdentifiedObjectContext = () => useContext(SelectedIdentifiedObjectContext);

const SelectedIdentifiedObjectProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [selectedIdentifiedObjects, setSelectedIdentifiedObjects] = useState<IdentifiedObjectItem[] | null>(null);
  const [allIdentifiedObjects, setAllIdentifiedObjects] = useState<IdentifiedObjectItem[] | null>(null);

  return (
    <SelectedIdentifiedObjectContext.Provider value={{ allIdentifiedObjects, setAllIdentifiedObjects, selectedIdentifiedObjects, setSelectedIdentifiedObjects }}>
      {children}
    </SelectedIdentifiedObjectContext.Provider>
  );
};

export default SelectedIdentifiedObjectProvider;