import React, { createContext, useContext, useState, ReactNode } from 'react';

interface LocationContextProps {
    useMyLocationClicked: boolean;
    error: string | null;
    handleUseMyLocationClick: () => void;
    resetUseMyLocationClick: () => void;
    setErrorMessage: (error: string) => void;
    resetErrorMessage: () => void;
}

const UserLocationContext = createContext<LocationContextProps>({
    useMyLocationClicked: false,
    error: null,
    handleUseMyLocationClick: () => {},
    resetUseMyLocationClick: () => {},
    setErrorMessage: () => {},
    resetErrorMessage: () => {},
  });
export const useUserLocationContext = () => useContext(UserLocationContext);
interface UserLocationProviderProps {
    children: ReactNode;
}

export const UserLocationProvider: React.FC<UserLocationProviderProps> = ({ children }) => {
    const [useMyLocationClicked, setUseMyLocationClicked] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleUseMyLocationClick = () => {
        setUseMyLocationClicked(true);
    };

    const resetUseMyLocationClick = () => {
        setUseMyLocationClicked(false);
    };

    const setErrorMessage = (errorMessage: string) => {
        setError(errorMessage);
      };
    
    const resetErrorMessage = () => {
        setError(null);
      };

    const contextValue: LocationContextProps = {
        useMyLocationClicked,
        error,
        handleUseMyLocationClick,
        resetUseMyLocationClick,
        setErrorMessage,
        resetErrorMessage,
    };

    return (
        <UserLocationContext.Provider value={contextValue}>
            {children}
        </UserLocationContext.Provider>
    );
};