import Select, { MultiValue } from "react-select";
import { CameraItem } from "../../models/Camera";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getCameras } from "../../services/Camera";
import { Spinner } from "react-bootstrap";

interface CameraProps {
  mode?: string;
  allCameras: CameraItem[];
  setAllCameras: Dispatch<SetStateAction<CameraItem[]>>;
  selectedCameras: CameraItem[];
  setSelectedCameras: Dispatch<SetStateAction<CameraItem[]>>;
}

const CamerasFilter: React.FC<CameraProps> = ({
  mode,
  allCameras,
  setAllCameras,
  selectedCameras,
  setSelectedCameras,
}) => {
  const [loading, setLoading] = useState(true);

  const loadCameras = () => {
    setLoading(true);
    const getCameras$ = getCameras({ page: "all" });
    const sub = getCameras$.subscribe({
      next: (res) => {
        console.log("res from camera", res);
        let mapped_data = res?.map((model: CameraItem) => {
          return { ...model, value: model.camera_id, label: model.camera_id };
        });
        setAllCameras(mapped_data);
        if (mapped_data.length > 0) {
          setSelectedCameras([mapped_data[0]]);
        }
        setLoading(false);
      },
      error: (error) => {
        console.error("Error loading cameras:", error);
        setLoading(false);
      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    loadCameras();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCameraSelectChange = (
    selectedOptions: MultiValue<CameraItem>
  ) => {
    // Map selected options (MultiValue) to CameraItem[]
    setSelectedCameras(selectedOptions as CameraItem[]);
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "70px",
            textAlign: "center",
          }}
        >
          <Spinner
            animation="border"
            role="status"
            style={{ marginBottom: "10px" }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div>Loading cameras filter ...</div>
        </div>
      ) : (
        <div>
          {allCameras && allCameras.length > 0 && (
            <div className="mb-1">
              <span className="d-block fw-bold mb-1">
                <small>Cameras:</small>
              </span>
              <Select
                inputId="camera-select"
                options={allCameras}
                isMulti
                defaultValue={selectedCameras}
                onChange={handleCameraSelectChange}
                placeholder="Select..."
                className="basic-multi-select"
                classNamePrefix="select"
                styles={{
                  control: (provided: any) => ({
                    ...provided,
                    // backgroundColor: "#F5F8FB",
                    borderColor: "#d1d7e0",
                    fontSize: 13,
                  }),
                  placeholder: (provided: any) => ({
                    ...provided,
                    color: "#506690",
                  }),
                  option: (provided: any) => ({
                    ...provided,
                    fontSize: 13,
                  }),
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CamerasFilter;
