import { AuthContextProps } from "react-oidc-context";
import { getHeaders } from "../services/ServiceUtil";

const fetchData = async (resourse_url: string, filters: string = '', method: string = "GET", auth?: AuthContextProps) => {

    const baseUrl = `${resourse_url}?${filters}`
    let currentPage = 1;
    let allData: any = [];
    let headers = {};

    // Use custom auth instead of globally stored storage oidc value
    if(auth){
        headers = {
            'Authorization': `Bearer ${auth.user?.access_token}`,
            'Content-Type': 'application/json'
            // You can add other headers as needed
        }
    }else{
        headers = getHeaders()
    }

    try {
      while (true) {
        const response = await fetch(`${baseUrl}&page=${currentPage}`,{method: method, headers});
        const newData = await response.json(); // Assuming the API response format contains the data you need

        if (!newData?.results) {
          // No data found
          break;
        }

        allData = [...allData, ...newData.results];
        currentPage++;

        if (!newData?.next && !newData?.links?.next) {
          // No more data after this one
          break;
        }
      }

      return allData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

export default fetchData;
