import { User } from "oidc-client-ts";

export const baseURL = `/api/v1`;

const loadUserFromStorage = () => {
  for (let i = 0; i < window.localStorage.length; i++) {
      if (localStorage.key(i)?.startsWith("oidc.user")) {
          const userString = localStorage.getItem(localStorage.key(i) as string);
          return User.fromStorageString(userString as string);
      }
  }
  return null;
};

export const getToken = () => {
  return loadUserFromStorage()?.access_token
}


export const getHeaders = () => ({
  "Content-Type": "application/json",
  "Authorization": `Bearer ${getToken()}`
  });