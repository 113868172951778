import React, { useEffect, useState } from "react";
import { ChoiceGroup, TextField } from "@fluentui/react";
import { Button } from "react-bootstrap";
import { MediaAnalysisItem } from "../models/Media";

interface Option {
  key: string;
  text: string;
}

interface MediaAnalysisFormProps {
  options: Option[];
  onSave: (
    hasAICorrectlyDetected: boolean,
    comment: string,
    id: number | null
  ) => void;
  onCancel?: () => void;
  cancelButtonText?: string;
  saveButtonText: string;
  data?: MediaAnalysisItem | null;
  resetComment?: boolean;
}

const MediaAnalysisForm: React.FC<MediaAnalysisFormProps> = ({
  options,
  onSave,
  cancelButtonText,
  saveButtonText,
  onCancel,
  data,
  resetComment,
}) => {
  const [hasAICorrectlyDetected, setHasAICorrectlyDetected] = useState(false);
  const [comment, setComment] = useState("");
  const [id, setId] = useState<number | null>(null);

  useEffect(() => {
    if (data) {
      setComment(data.comment);
      setId(data.id);
      setHasAICorrectlyDetected(data.has_ai_correctly_detected);
    }
  }, [data]);

  // Effect to reset the comment field
  useEffect(() => {
    if (resetComment) {
      setComment(""); // Reset the comment text
    }
  }, [resetComment]);

  const handleChoiceChange = (
    e?: React.FormEvent<HTMLInputElement | HTMLElement>,
    selectedOption?: Option
  ): void => {
    const selectedValue = selectedOption?.key === "true";
    setHasAICorrectlyDetected(selectedValue);
  };

  const saveMediaAnalysis = (): void => {
    onSave(hasAICorrectlyDetected, comment, id);
  };

  return (
    <div>
      <ChoiceGroup
        id={`MediaAnalysisFormchoices-${saveButtonText}`}
        selectedKey={hasAICorrectlyDetected ? "true" : "false"}
        options={options.map((option) => ({
          ...option,
          styles: {
            root: {
              marginRight: "10px", // Add spacing between individual options
              fontSize: "14px",
            },
          },
        }))}
        onChange={handleChoiceChange}
        // label="Pick one"
        required={true}
        styles={{
          flexContainer: {
            display: "flex",
            justifyContent: "flex-start",
          },
        }}
      />
      {!hasAICorrectlyDetected && (
        <TextField
          className="mt-3"
          id={`MediaAnalysisFormTextBox-${saveButtonText}`}
          placeholder="Add notes"
          multiline
          rows={3}
          value={comment}
          onChange={(_, newValue) => setComment(newValue || "")}
          styles={{
            fieldGroup: {
              border: "1px solid lightgrey",
            },
          }}
        />
      )}
      <div className="d-flex justify-content-end mt-3">
        <Button size="sm" variant="primary" onClick={() => saveMediaAnalysis()}>
          {saveButtonText}
        </Button>
        {cancelButtonText && (
          <Button
            variant="secondary"
            style={{ marginLeft: "10px" }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};

export default MediaAnalysisForm;
