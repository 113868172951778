import { useState, useEffect, useRef } from 'react';

// custom hook to sync state between tabs / windows
// based on https://javascript.plainenglish.io/a-react-hook-to-share-state-between-browser-windows-a672470f66ff

function useSharedState(stateKey: any, defaultValue: any) {
  const [state, setState] = useState<any>(defaultValue)
  const init = useRef(true)

  useEffect(() => {
    // initialise if this is the first time this hook is called
    if (init.current) {
      const currentState = localStorage.getItem(stateKey)
      if (currentState) {
        setState(JSON.parse(currentState))
      } else {
        setState(defaultValue)
      }
      init.current = false
      return
    }
    // set value
    try {
      localStorage.setItem(stateKey, JSON.stringify(state))
    } catch (error) { }
  }, [state, stateKey, defaultValue])

  // handle changes from other tabs
  useEffect(() => {
    const onReceieveMessage = (e: { key: any; newValue: any; }) => {
      const { key, newValue } = e
      if (key === stateKey) {
        setState(JSON.parse(newValue))
      }
    }
    window.addEventListener('storage', onReceieveMessage)
    return () => window.removeEventListener('storage', onReceieveMessage)
  }, [stateKey, setState])


  return [state, setState]
}

export default useSharedState;