import { useEffect, useState } from "react";
import { Offcanvas, ListGroup, Nav, Modal, Button } from "react-bootstrap";
import { useNotificationItemsContext } from "../context/NotificationContext";
import { EditNotificationItem, NotificationItem } from "../models/Notification";
import { NotificationPaginator } from "./NotificationPaginator";
import {
  editNotification,
  readAllNotification,
} from "../services/Notification";
import Select from "react-select";
import { getCameraFilters } from "../services/Camera";

interface NotificationsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function Notifications({ open, setOpen }: NotificationsProps) {
  const notificationContext = useNotificationItemsContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState("active");
  const [showConfirm, setShowConfirm] = useState(false);
  const [cameraFilters, setCameraFilters] = useState([]);
  const [selectedCameraFilter, setSelectedCameraFilter] = useState<any[]>([]); // Allow multiple selections

  const handleConfirmClose = () => setShowConfirm(false);
  const handleConfirmShow = () => setShowConfirm(true);

  // Fetch available camera filters
  const fetchCameraFilters = () => {
    const getCameraFilter$ = getCameraFilters();
    const sub = getCameraFilter$.subscribe({
      next: (res: any) => {
        let mapped_data = res.map((camera: any) => {
          return {
            ...camera,
            value: camera.camera_id,
            label: camera.camera_id,
          };
        });
        setCameraFilters(mapped_data);
      },
    });
    return () => sub.unsubscribe();
  };

  // Fetch notifications with selected camera filters
  const fetchNotifications = () => {
    const filterParams = {
      is_read: currentTab === "active" ? false : true,
      page: currentPage,
      camera_ids: selectedCameraFilter.map((camera: any) => camera.id), // Include selected camera IDs
    };
    notificationContext?.fetchNotificationItem(filterParams);
  };

  useEffect(() => {
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 30000);
    return () => clearInterval(intervalId);
  }, [currentTab, currentPage, selectedCameraFilter]); // Add selectedCameraFilter to dependencies

  useEffect(() => {
    fetchCameraFilters();
  }, []);

  // Handle changes in react-select (camera filter)
  const handleCameraSelectChange = (selectedOptions: any) => {
    setSelectedCameraFilter(selectedOptions || []); // Set selected camera filters or empty array if none
  };

  const acknowledgeNotification = (notification: NotificationItem) => {
    const payload: EditNotificationItem = {
      is_read: true,
      message: notification.message,
      severity: notification.severity,
    };
    const postNotification$ = editNotification(notification.id, payload);
    const sub = postNotification$.subscribe({
      next: () => {
        fetchNotifications();
      },
    });
    return () => sub.unsubscribe();
  };

  const handleMarkAllAsRead = () => {
    handleConfirmClose(); // Close modal
    const readNotification$ = readAllNotification();
    const sub = readNotification$.subscribe({
      next: () => {
        fetchNotifications();
      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [currentTab]);

  return (
    <Offcanvas
      show={open}
      onHide={() => setOpen(false)}
      placement="end"
      style={{ width: "550px" }}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="w-100 text-center">
          Notifications
        </Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body style={{ padding: 0 }}>
        <Nav variant="tabs" activeKey={currentTab} className="w-100">
          <Nav.Item className="d-flex">
            <Nav.Link
              eventKey="active"
              onClick={() => setCurrentTab("active")}
              className="w-50 text-center"
            >
              Active
            </Nav.Link>
            <Nav.Link
              eventKey="past"
              onClick={() => setCurrentTab("past")}
              className="w-50 text-center"
            >
              Past
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="p-2">
            <Nav.Link
              as="span"
              className="text-secondary cursor-pointer text-decoration-underline fw-bold"
              onClick={handleConfirmShow}
              style={{ cursor: "pointer", display: "inline-block" }}
            >
              Mark all as read
            </Nav.Link>
          </Nav.Item>
        </Nav>

        {/* Camera Filter */}
        <div className="px-3 my-2">
          <span className="d-block fw-bold mb-1">
            <small>Cameras:</small>
          </span>
          <Select
            options={cameraFilters}
            isMulti
            onChange={handleCameraSelectChange}
            placeholder="Filter by Camera ..."
            className="basic-multi-select"
            classNamePrefix="select"
            styles={{
              control: (provided: any) => ({
                ...provided,
                borderColor: "#d1d7e0",
              }),
              placeholder: (provided: any) => ({
                ...provided,
                color: "#506690",
                fontSize: 13,
              }),
              option: (provided: any) => ({
                ...provided,
                // fontSize: 13,
              }),
            }}
          />
        </div>

        {/* Notification List */}
        {notificationContext?.notificationItem &&
        notificationContext.notificationItem.length > 0 ? (
          <div className="w-100">
            <ListGroup>
              {notificationContext?.notificationItem?.map((notification) => (
                <ListGroup.Item
                  key={notification.id}
                  style={{
                    borderBottom: "1px solid #dee2e6",
                    borderTop: "None",
                    borderRadius: "0",
                    marginTop: "0",
                    minHeight: "80px",
                    fontSize: "15px",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-start">
                      {notification.severity === "Confirmation" && (
                        <i
                          className="text-success bi bi-check-circle-fill"
                          style={{ marginRight: "10px" }}
                        />
                      )}
                      {notification.severity === "Warning" && (
                        <i
                          className="text-warning bi bi-exclamation-circle-fill"
                          style={{ marginRight: "10px" }}
                        />
                      )}
                      {notification.severity === "Error" && (
                        <i
                          className="text-danger bi bi-x-circle-fill"
                          style={{ marginRight: "10px" }}
                        />
                      )}
                      <div>
                        <strong>
                          {notification.severity === "Confirmation" &&
                            "Action Completed"}
                          {notification.severity === "Warning" &&
                            "Attention Needed"}
                          {notification.severity === "Error" &&
                            "Error Occurred"}
                        </strong>
                        <p className="mb-0">
                          {notification.message}
                          <span style={{ display: "block" }}>
                            Created at:{" "}
                            {new Date(notification.created_at).toLocaleString(
                              "en-CA",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false, // For 24-hour format
                              }
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                    <Nav>
                      <Nav.Item>
                        {notification.is_read === false && (
                          <Nav.Link
                            className="text-secondary cursor-pointer text-decoration-underline"
                            onClick={() =>
                              acknowledgeNotification(notification)
                            }
                            style={{
                              marginLeft: "20px",
                              whiteSpace: "nowrap",
                              pointerEvents: "auto",
                            }}
                          >
                            Acknowledge
                          </Nav.Link>
                        )}
                      </Nav.Item>
                    </Nav>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        ) : (
          <div className="text-center py-3">No Notifications to Display</div>
        )}

        {/* Pagination */}
        {notificationContext?.totalItems > 0 && (
          <div className="mb-5">
            <NotificationPaginator
              currentPage={currentPage}
              totalItems={notificationContext?.totalItems}
              itemsPerPage={10}
              onPageChange={setCurrentPage}
            />
          </div>
        )}

        {/* Confirmation modal */}
        <Modal show={showConfirm} onHide={handleConfirmClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Action</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to mark all notifications as read?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleConfirmClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleMarkAllAsRead}>
              Mark all as read
            </Button>
          </Modal.Footer>
        </Modal>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
