import ReactPlayer from "react-player";
import { Card, Col, Row } from "react-bootstrap";
import { useSelectedMediaContext } from "../context/MediaContext";
import placeholder from "../assets/images/video-player.png";
import { MediaType } from "../models/Media";

// MediaDisplay component to handle rendering based on media type
const MediaDisplay: React.FC<{ mediaType: "video" | "image"; url: string }> = ({
  mediaType,
  url,
}) => {
  if (mediaType === MediaType.Video) {
    // Render video player for video media type
    return <ReactPlayer url={url} controls width="100%" height="100%" />;
  } else if (mediaType === MediaType.Image) {
    // Render image for image media type
    return (
      <img
        src={url}
        alt="Image"
        style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
      />
    );
  } else {
    // Render a message for unsupported media type
    return <p>Unsupported media type</p>;
  }
};

// MediaPlayer component to display selected media
const MediaPlayer = () => {
  const { selectedMedia } = useSelectedMediaContext();

  if (!selectedMedia) {
    // Render placeholder if no media is selected
    return (
      <Card border="light" className="shadow-sm">
        <Card.Body
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            style={{
              maxWidth: "100%",
              maxHeight: "400px",
              margin: "auto",
              paddingBottom: "1rem",
            }}
          >
            <img
              src={placeholder}
              alt="Placeholder"
              style={{ width: "80%", height: "auto", objectFit: "contain" }}
            />
          </div>
          <h5 style={{ marginBottom: "1rem" }}>
            Please select a video to play.
          </h5>
        </Card.Body>
      </Card>
    );
  }

  // Render card with selected media information and MediaDisplay component
  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col
            xs={12}
            xl={12}
            className="align-items-center justify-content-xl-center"
          >
            <div className="d-none d-sm-block">
              <h6>Title: {selectedMedia?.title}</h6>
            </div>
          </Col>
        </Row>
        <div style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}>
          {selectedMedia && (
            <MediaDisplay
              mediaType={selectedMedia.media_type}
              url={selectedMedia.media_file}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default MediaPlayer;
