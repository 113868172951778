import {
  Col,
  ListGroup,
  Row,
  FormControl,
  InputGroup,
  Button,
} from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import { useContext, useEffect, useState } from "react";
import CustomModal from "../components/CustomModal";
import { getCameras } from "../services/Camera";
import { CameraItem } from "../models/Camera";
import AddEditCamera from "../components/AddEditCamera";
import { SignInContext } from "../context/SignInContext";
import Spinner from "../components/Spinner";
import { FaSearch, FaTimes } from "react-icons/fa";
import { NotificationPaginator } from "../components/NotificationPaginator";

const ManageCameras = () => {
  const [cameras, setCameras] = useState<CameraItem[]>([]);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [pageData, setPageData] = useState<any>(null); // For pagination data
  const [formData, setFormData] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); // Search input state
  const [cameraFilter, setCameraFilter] = useState(""); // Search filter state
  const [loading, setLoading] = useState(false); // Set to false initially
  const [_lastCamerasCount, setLastCamerasCount] = useState<number>(0); // For displaying N cameras found
  const { isAdminUser, userGroupsCount } = useContext(SignInContext);
  const itemsPerPage = 8; // Number of items per page

  // Load cameras based on the current search and page number
  const loadCameras = () => {
    setLoading(true); // Start loading state
    const getCameras$ = getCameras({
      page: pageNumber,
      search: cameraFilter,
      pageSize: itemsPerPage,
    });
    const sub = getCameras$.subscribe({
      next: (res: any) => {
        setPageData(res); // Pagination data
        const cameraResults = res.results;
        const camerasWithoutGPS = cameraResults.filter(
          (camera: { has_gps: boolean }) => camera.has_gps === false
        );
        isAdminUser ? setCameras(cameraResults) : setCameras(camerasWithoutGPS);
        setLastCamerasCount(cameraResults.length); // Update the last fetched count
        setLoading(false); // Stop loading once data is received
      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    if (userGroupsCount != null) {
      loadCameras();
    }
  }, [userGroupsCount, pageNumber, cameraFilter]);

  // Handle page change for pagination
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };

  // Handle search query when the user presses "Enter"
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setCameraFilter(searchQuery); // Update the filter state
      setPageNumber(1); // Reset to the first page
    }
  };

  // Handle manual search via the search button
  const handleSearch = () => {
    setCameraFilter(searchQuery);
    setPageNumber(1); // Reset to the first page
  };

  return (
    <PageLayout title="Manage Cameras">
      <CustomModal
        show={showAddModal}
        scrollable={false}
        modalClassName={"add-camera-entry-modal-dialog"}
        modalBody={
          <AddEditCamera
            formData={formData}
            onClose={() => {
              setShowAddModal(false);
              setFormData(null);
              loadCameras();
            }}
          />
        }
      />

      <Row className="mb-2 justify-content-md-end g-2 mt-n5 ">
        {/* Search Input - Expands to Full Width on Small Screens */}
        <Col xs={12} md="auto" className="mb-2 mb-md-0">
          <InputGroup>
            <FormControl
              placeholder="Search by Name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyPress} // Trigger search on Enter
            />
            {searchQuery && (
              <span
                onClick={() => {
                  setSearchQuery(""); // Clear the search query
                  setCameraFilter(""); // Clear camera filter
                  setPageNumber(1); // Reset pagination
                }}
                style={{
                  position: "absolute",
                  right: "55px",
                  top: "50%",
                  transform: "translateY(-50%)", // Center vertically
                  cursor: "pointer",
                }}
              >
                <FaTimes />
              </span>
            )}
            <Button variant="secondary" onClick={handleSearch}>
              <FaSearch />
            </Button>
          </InputGroup>
        </Col>

        {/* Add Camera Button - Expands to Full Width on Small Screens */}
        <Col xs={12} md="auto">
          {isAdminUser && (
            <Button
              variant="primary"
              onClick={() => setShowAddModal(true)}
              className="w-100 w-md-auto"
            >
              + Add Camera
            </Button>
          )}
        </Col>
      </Row>

      {/* Camera list */}
      <Row className="position-relative g-2">
        {/* Display the "No Cameras Found" message if no results and not currently loading */}
        {cameras.length === 0 && (
          <div className="mt-3 d-flex justify-content-center bg-white">
            <div className="no-results-found p-4 text-center">
              <h5>No Cameras Found</h5>
              <p>Try adjusting your search or adding new cameras.</p>
            </div>
          </div>
        )}

        {/* Show loading spinner if loading */}
        {loading && (
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.7)", zIndex: 1 }}
          >
            <Spinner />
          </div>
        )}

        {/* Camera list */}
        <ListGroup>
          {cameras.map((camera) => (
            <ListGroup.Item
              className="d-flex justify-content-between align-items-start mb-1 px-3" // Added mb-2 and px-3 for consistent spacing
              key={camera.id}
            >
              <div className="ms-2 me-auto p-0">
                <div>
                  <span className="fw-bold">{camera.camera_id}</span>
                  <small className="d-block">
                    {camera.group_name
                      ? `Managed by ${camera.group_name} group`
                      : "No group assigned yet"}
                  </small>
                  <small className="d-block">
                    {camera.region_name
                      ? `Located in ${
                          camera.region_name.charAt(0).toUpperCase() +
                          camera.region_name.slice(1).toLowerCase()
                        } region`
                      : "No location assigned yet"}
                  </small>
                </div>
              </div>
              <div className="list-item-wrapper d-flex align-items-center">
                <button
                  type="button"
                  className="btn list-action-button btn-light"
                  onClick={() => {
                    setFormData(camera);
                    setShowAddModal(true);
                  }}
                >
                  <i className="bi bi-pencil-square fs-5"></i>
                </button>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Row>

      {/* Paginator */}
      {pageData && (
        <NotificationPaginator
          currentPage={pageNumber}
          totalItems={pageData.count}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
        />
      )}
    </PageLayout>
  );
};

export default ManageCameras;
